<template>
  <div>
    <el-container>
      <el-container>
        <el-header>
          <p style="margin: 10px" class="Ptitle">{{ room.tvrName }}</p>
        </el-header>
        <el-main>
          <div id="video_div" style="width: 70%; text-align: center; padding-left: 15%; padding-top: 20px">
            <video id="video" class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9" controls
                   preload="auto" width="100%">
              <source :src="room.tvrUrl" type="application/x-mpegURL"/>
            </video>
          </div>
        </el-main>
        <el-footer>
          <el-row type="flex" class="row_div" justify="space-between">
            <el-col :span="3">
              <el-button size="mini" plain class="el-icon-close-notification" v-if="videoMuted" style="font-size: 40px;"
                         @click="videoOperation('muted_false')"/>
              <el-button size="mini" plain class="el-icon-bell" v-else style="font-size: 40px;"
                         @click="videoOperation('muted_true')"/>
            </el-col>
            <el-col :span="3">
              <el-button size="mini" plain class="el-icon-video-pause" v-if="videoPlayStatus" style="font-size: 40px;"
                         @click="videoOperation('pause')"/>
              <el-button size="mini" plain class="el-icon-video-play" v-else style="font-size: 40px;"
                         @click="videoOperation('play')"/>
            </el-col>
            <el-col :span="3">
              <el-button size="mini" plain class="el-icon-refresh" style="font-size: 40px;"
                         @click="videoOperation('refreshVideo')"/>
            </el-col>
            <el-col :span="3" v-if="room.homeowner">
              <el-button size="mini" plain class="el-icon-c-scale-to-original" style="font-size: 40px;"
                         @click="videoOperation('synVideoTime')"/>
            </el-col>
            <el-col :span="3" v-else>
              <el-button size="mini" plain class="el-icon-full-screen" style="font-size: 40px;"
                         @click="videoOperation('synFullVideo')"/>
            </el-col>
            <el-col :span="3">
              <el-button size="mini" plain class="el-icon-link" style="font-size: 40px;"
                         @click="videoOperation('share')"/>
            </el-col>
          </el-row>
        </el-footer>
      </el-container>
      <el-aside width="500px" :style="'height: ' + asideHeight + 'px'">
        <index8 lemonWidth="500px" :lemonHeight="asideHeight + 'px'" @pullMessage="pullMessage"
                @sentMessage="sentMessage"
                :user="userInfo" ref="index8"
                :roomName="room.tvrName"/>
      </el-aside>
    </el-container>
  </div>
</template>

<script>
import index8 from '../../test/index8'
import 'video.js/dist/video-js.css'
import videojs from "video.js";
import "videojs-contrib-hls";
import RoomAsk from "../../../request/expand/tvRoom/index";
import cryptoUtil from "@/utils/cryptoUtil";

export default {
  name: "OnLineCinema_PC",
  props: {
    room: {
      type: Object,
      default: null
    }
  },
  components: {
    index8
  },
  data() {
    return {
      myPlayer: null, //播放器实列
      roomAsk: new RoomAsk(),
      userInfo: {
        id: this.$store.state.user.userInfo.lUsrId,
        displayName: this.$store.state.user.userInfo.lUsrName,
        avatar: '/api/resources/user/' + this.$store.state.user.userInfo.lUsrId + '/' + this.$store.state.user.userInfo.lUsrIcon
      },
      videoUrl: "https://1500005692.vod2.myqcloud.com/43843706vodtranscq1500005692/62656d94387702300542496289/v.f100240.m3u8",
      webSock: null, // 连接实体
      wsSessionId: null, // 连接唯一标识,
      wsManualClose: false, // 手动关闭连接
      videoPlayStatus: false, // 视频播放状态
      videoMuted: true, // 视频静音状态
      numberMessages: 0, // 未读消息数量
      popoverOrNot: false, // 是否弹出重连弹框
      asideHeight: 0,
    }
  },
  methods: {
    initPage(url) {
      this.videoUrl = this.room.tvrUrl
      this.room.tvrUrl = url
      this.initWebSocket();
    },
    /**
     * 初始化websocket
     */
    initWebSocket() {
      if (typeof WebSocket === 'undefined') {
        return console.log('您的浏览器不支持websocket')
      }
      if (document.location.protocol.indexOf("https") > -1) {
        this.webSock = new WebSocket("wss:" + location.host + '/websocket/ws/' + this.room.tvrName + '/tv')
      } else {
        this.webSock = new WebSocket("ws:" + location.host + '/websocket/ws/' + this.room.tvrName + '/tv')
      }
      this.webSock.onmessage = this.webSocketOnMessage
      this.webSock.onopen = this.webSocketOnOpen
      this.webSock.onerror = this.webSocketOnError
      this.webSock.onclose = this.webSocketClose
    },
    /**
     *  连接建立
     */
    webSocketOnOpen() {
      // 连接建立之后执行send方法发送数据
      // this.webSocketSend()
      this.initVideoPlay();
      this.wsManualClose = false
    },
    /**
     * 连接异常
     */
    webSocketOnError() {
      // 连接建立失败重连
      console.log("连接建立失败重连")
      if (!this.wsManualClose) {
        this.reconnection();
      }
      // this.initWebSocket()
    },
    /**
     * 关闭连接
     * @param e
     */
    webSocketClose(e) {
      if (!this.wsManualClose) {
        this.reconnection();
      }
    },
    /**
     * ws 重新连接
     */
    reconnection() {
      if (!this.popoverOrNot) {
        this.popoverOrNot = true
        this.$confirm('断开连接, 是否重连?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.initWebSocket()
          this.popoverOrNot = false;
        }).catch(() => {
          this.wsManualClose = true
          this.popoverOrNot = false;
          this.$router.go(-1)
          this.$message({
            type: 'info',
            message: '已取消重连'
          });
        });
      }
    },
    /**
     * webSocket发送消息
     * @param e
     */
    webSocketOnMessage(e) {
      // 数据接收
      const resData = JSON.parse(e.data)
      this.messageTypeProcessing(resData);
      // console.log('接收的数据', resData)
    },
    /**
     * 发送消息逻辑处理
     * @param sendTo 发送至
     * @param operationType 操作类型
     * @param operationValue 操作值
     * @param toSent 发送人
     */
    webSocketSend(sendTo, operationType, operationValue) {
      let data = {
        sendTo: sendTo,
        operationType: operationType,
        operationValue: operationValue,
        toSent: this.userInfo.displayName
      }
      // 数据发送
      this.webSock.send(JSON.stringify(data))
    },
    /**
     * 聊天组件发送信息
     * @param data
     */
    sentMessage(data) {
      this.webSocketSend("ALL", "chatSentMessageAll", JSON.stringify(data));
    },
    /**
     * 消费消息逻辑处理
     * @param data
     */
    messageTypeProcessing(data) {
      // 标记用户唯一标识
      if (data.operationType == 'markingUser') {
        this.wsSessionId = data.operationValue;
      }
      // 接收聊天内容
      else if (data.operationType == 'chatSentMessageAll') {
        let msgData = JSON.parse(data.operationValue);
        msgData.status = 'succeed'
        if (msgData.content.indexOf("qh:") != -1) {
          const link = msgData.content.substring(3, msgData.content.length);
          this.videoUrl = link
          this.videoPlay(link);
          // 自己发送自己接收(修改消息状态)
          if (msgData.fromUser.displayName == this.userInfo.displayName) {
            this.$refs.index8.updateMessage(msgData);
            this.roomAsk.updRoomInfoUrl({
              "tvrName": new cryptoUtil().encryptFun(this.room.tvrName),
              "tvrUrl": link
            })
          } else {
            msgData.content = "视频切换完成, 请手动点击播放!";
            this.$refs.index8.renderMessage(msgData);
          }
          return false;
        }
        // 自己发送自己接收(修改消息状态)
        if (msgData.fromUser.displayName == this.userInfo.displayName) {
          this.$refs.index8.updateMessage(msgData);
        } else {
          this.$refs.index8.renderMessage(msgData);
        }
      }
      // 跟随房主视频播放时间
      else if (data.operationType == 'changeVideoTime') {
        if (data.toSent != this.userInfo.displayName) {
          this.myPlayer.currentTime(Number(data.operationValue))
        }
      }
      // 跟随房主倍数速率
      else if ('ratechangeVideo' == data.operationType) {
        if (data.toSent != this.userInfo.displayName) {
          this.myPlayer.playbackRate(Number(data.operationValue))
        }
      }
      // 跟随房主视频播放暂停
      else if (data.operationType == 'changeVideoPause') {
        if (data.toSent != this.userInfo.displayName) {
          this.myPlayer.pause()
        }
      }
      // 跟随房主视频播放播放
      else if (data.operationType == 'changeVideoPlay') {
        if (data.toSent != this.userInfo.displayName) {
          this.myPlayer.play()
        }
      } else if (data.operationType == 'changeVideoLink') {
        this.videoPlay(data.operationValue);
      }
    },
    /**
     * 消息内容聊天组件显示
     * @param content
     */
    laiMsg(message) {
      message.status = 'succeed';
      this.$refs.index8.renderMessage(message);
    },
    /**
     * 视频播放器初始化
     */
    initVideoPlay() {
      if (this.myPlayer != null) {
        return;
      }
      const this_ = this;
      this.myPlayer = videojs('video',
          {
            playsinline: true,
            "poster": "https://www.toopic.cn/public/uploads/small/1658043292312165804329268.png",
            "controls": this.room.homeowner ? true : false,
            "preload": "auto",
            "autoplay": false,
            "muted": true,
            playbackRates: [0.5, 1, 1.5, 2, 3]
          }, function () {
            this_.$message({
              showClose: true,
              message: '视频准备完成, 请手动点击播放!',
              type: 'success'
            });
            this.on('play', function () {
              this_.videoPlayStatus = true;
            });
            this.on('pause', function () {
              this_.videoPlayStatus = false;
            });
            this.on("error", function () {
              this_.$message.error('视频加载错误');
            });
            this.on("stalled", function () {
              this_.$message({
                message: '网速异常~',
                type: 'warning'
              });
            })
            if (this_.myPlayer == null) {
              this.on('play', function () {
                console.log('正在播放');
                this_.webSocketSend("ALL", "changeVideoPlay", null);
              });
              //暂停--播放完毕后也会暂停
              this.on('pause', function () {
                console.log("暂停中")
                this_.webSocketSend("ALL", "changeVideoPause", null);
              });
              // 结束
              this.on('ended', function () {
                console.log('结束');
              })
              // 等待数据
              this.on('waiting', function () {
                console.log("等待数据")
              })
              this.on("canplaythrough", function () {
                console.log("视频源数据加载完成")
              })
              this.on("seeking", function () {
                this_.webSocketSend("ALL", "changeVideoTime", this.myPlayer.currentTime());
              })
            }
          });
    },
    /**
     * 重新播放
     */
    videoPlay(src) {
      this.myPlayer.src([
        {
          src: src
        }
      ]);
      this.videoPlayStatus = false;
      this.videoMuted = true;
    },
    /**
     * 视频播放操作
     * @param type 操作类型
     */
    videoOperation(type) {
      if ('play' === type) {
        this.myPlayer.play()
      } else if ('pause' === type) {
        this.myPlayer.pause()
      } else if ('synVideoTime' === type) {
        this.webSocketSend("ALL", "changeVideoTime", this.myPlayer.currentTime());
        this.webSocketSend("ALL", "ratechangeVideo", this.myPlayer.playbackRate());
      } else if ('muted_false' === type) {
        this.myPlayer.muted(false)
        this.videoMuted = false
      } else if ('muted_true' === type) {
        this.myPlayer.muted(true)
        this.videoMuted = true
      } else if ('refreshVideo' === type) {
        if (this.myPlayer) {
          this.myPlayer.dispose();
          this.myPlayer = null;
          let myVideoDiv = document.getElementById("video_div")
          myVideoDiv.innerHTML = '<video id="video" class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9">'
          this.initVideoPlay();
          this.videoPlay(this.videoUrl)
        }
      } else if ('synFullVideo' == type) {
        this.myPlayer.requestFullscreen()
      }else if ('share' === type) {
        let textToCopy = 'https://charonv.com/expand/weChat/jump?redirect_uri=onLineCinema?vid=' + this.$route.query.vid
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
          this.$message('已复制链接, 请分享至微信.');
        } catch (err) {
          this.$message({
            showClose: true,
            message: '无法复制文本到剪贴板',
            type: 'error'
          });
        }
        document.body.removeChild(textArea);
      }
    },

    /**
     * 获取历史消息
     * @param indexI
     * @returns {*[]}
     */
    pullMessage(indexI, callback) {
      let data = {
        "pageSize": indexI,
        "tvrName": this.room.tvrName
      }
      this.roomAsk.getRoomMessages(data).then(res => {
        if (res.code == 'success') {
          callback(res.msg);
        }
      }).catch(e => {
        callback([]);
      })
    }
  },
  created() {
    this.asideHeight = window.innerHeight - 61
  },
  mounted() {

  },
  destroyed() {
    //页面销毁，同时也销毁video.js对象
    if (this.myPlayer) {
      this.myPlayer.dispose();
      this.myPlayer = null;
    }
    if (this.webSock) {
      this.wsManualClose = true
      this.webSock.close();
    }
  },
  watch: {}
}
</script>

<style scoped>
.el-header, .el-footer {
  background-color: #c3cfe2;
//color: #333; text-align: center;
}

.el-aside {
//background-color: #D3DCE6; //color: #333; //background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%); background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%); text-align: center;
}

.el-main {
//background-color: #c3cfe2; //color: #333; background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%); text-align: center; padding: 0;
}

body > .el-container {
  margin-bottom: 40px;
}


/deep/ .el-dialog__body {
  padding: 0px;
}

/deep/ .el-dialog {
  opacity: 0.5;
}
</style>