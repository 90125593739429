<template>
  <div>
    <mobile v-if="isPhone_var" :room="room" ref="mobile"/>
    <pc v-else :room="room" ref="pc"/>
  </div>
</template>

<script>
import {isPhone} from "@/utils/validate";
import tvRoom_Index from "../../../request/expand/tvRoom";
import mobile from "./OnLineCinema.vue"
import pc from "./OnLineCinema-PC.vue";

export default {
  name: "onLineCinema",
  components: {
    mobile,
    pc
  },
  data() {
    return {
      ask: new tvRoom_Index(),
      isPhone_var: true,
      room: {
        type: "tvRoom",
        homeowner: false,
        tvrName: "一起看",
        tvrUrl: null
      }
    }
  },
  mounted() {

  },
  created() {
    let trvName = this.$route.query.vid;
    this.isPhone_var = isPhone();
    if (!this.$store.getters["user/getIsLogin"]) {
      this.$router.push({path: '/login'})
      return
    }
    const userInfo = this.$store.state.user.userInfo
    // 获取房间信息
    this.ask.getTvRoomDecrypt({
      "tvrName": trvName
    }).then(res => {
      this.room.tvrName = res.tvrName
      this.room.tvrUrl = res.tvrUrl
      // 判断是否房间管理员
      if (userInfo && res.tvrCreationUsrId == userInfo.lUsrId) {
        this.room.homeowner = true;
      }
      // 数据加载完；
      if (this.isPhone_var) {
        this.$refs.mobile.initPage(res.tvrUrl);
      } else {
        this.$refs.pc.initPage(res.tvrUrl);
      }
    }).catch(e => {
      this.$router.go(-1)
    })
  },
  destroyed() {
    if (this.isPhone_var) {
      this.$refs.mobile.myPlayer.dispose();
    } else {
      this.$refs.pc.myPlayer.dispose();
    }
  }
}
</script>

<style scoped>

</style>